@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,400i,600,700,900);
@import url(https://img.iafcertsearch.org/r/flags/24/flags.min.css);
.styles_error-page__2YsBf {
  background: #023b89 url("https://img.iafcertsearch.org/r/p/public/img_geo.png") no-repeat right bottom;
  text-align: center;
  padding: 5rem 0;
  flex-grow: 1;
  flex-direction: column;
}
.styles_error-page__2YsBf .styles_error-msg__3RIvA {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 100;
}
.styles_error-page__2YsBf .styles_error-msg__icon__2R3IO {
  color: #f7e800;
}
.styles_error-page__2YsBf h1 {
  font-size: 11rem;
  color: #fff;
  font-family: "Helvetica Neue";
  line-height: 1;
}
.styles_error-page__2YsBf h2 {
  font-size: 3rem;
  color: #fff;
  line-height: 2;
}
.styles_error-page__2YsBf .styles_error-btn__Pu7z_ {
  padding: 0.75rem 4.5rem !important;
}
